<template>
  <transition name="fade" mode="out-in">
    <loading-component v-if="loading" message="Saliendo de la cuenta"/>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import Loading from '@/components/animations/Loading.vue';

export default {
  name: 'App',
  components: {
    'loading-component': Loading,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.logout()
      .then(response => {
        console.log('message: ' + (response.response && response.response.data && response.response.data.message ? response.response.data.message : '') + ' (redirecting)');
        this.$router.replace('/');
      });
  },
  methods: {
    ...mapActions('authentication', ['logout']),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
